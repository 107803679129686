import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {CardHeader, Grid, ImageList, ImageListItem, TableRow, Table, TableCell} from "@mui/material";
import Button from '@mui/material/Button';
import axios from "axios";
import {Auth} from "aws-amplify";
import {Image} from "@mui/icons-material";

//@ts-ignore
export default function ProductCard({data}) {
    let apiClient = axios.create({
        baseURL: "https://api.schunk.kai-krieg.de",
        withCredentials: false,
        headers: {
            Accept: "application/json",
            Authorization: '',
            "Content-Type": "application/json",
        },
    });
    console.log(data)
    const theme = useTheme();
    const request_handler = () => {
        Auth.currentAuthenticatedUser().then((user)=>{
            console.log(user.signInUserSession)
            let token = user.signInUserSession.idToken.jwtToken
            apiClient.get('/object-data/request-ownership/?serialnummer='+data.uuid,{headers:{Authorization: token}})
                .then((info:Object)=>{
                    //@ts-ignore
                    console.log(info.data)

                }).catch((er:Object)=>{
                console.log(er)
            })
        })

    }


    return (
        <Card variant={'outlined'} sx={{border:1, display: 'flex', flexDirection: 'column' ,m:'1cm', bgcolor: theme.palette.common.white, borderRadius: 5 }}>
            <a href={data['SchunkCom-Link']} target="_blank">
                <CardHeader
                    sx={{bgcolor: "primary.main", color:'common.white'}}
                    title={data.Name}
                    subheader={'Produktnummer: '+data.pn}
                />
            </a>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CardMedia
                    component="img"
                    sx={{ width: '25%', objectFit:'contain'}}
                    image={data.img}
                    alt="image"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        {data.user.attributes.name==''?
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component="div" variant="h5">
                                    Anmelden für mehr Details
                                </Typography>
                            </Box>
                            :
                            data.claimed ?
                                "owner" in data?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <ImageList rowHeight={164} variant="masonry" cols={3} gap={8}>
                                                {//@ts-ignore
                                                data.Images.map((item) => (
                                                    <ImageListItem>
                                                        <a href={item} target="_blank">
                                                            <img
                                                                src={item}
                                                                style={{width:'100%', objectFit: 'contain'}}
                                                                loading="lazy"
                                                            />
                                                        </a>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <embed style={{aspectRatio: '1/1.5'}} src={data.Lieferschein} width="100%" height="100%"/>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Table sx={{ width: '100%'}} aria-label="simple table">
                                                <TableRow>
                                                    <TableCell align="left">Kaufdatum:</TableCell>
                                                    <TableCell align="right">{data.bought}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">Garantie:</TableCell>
                                                    <TableCell align="right">{data.waranty}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">nächste Kalibrierung:</TableCell>
                                                    <TableCell align="right">{data.nextCalibration}</TableCell>
                                                </TableRow>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography component="div" variant="h5">
                                            auf dieses Objekt wurde bereits Anspruch erhoben
                                        </Typography>
                                    </Box>
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography component="div" variant="h5">
                                        auf dieses Objekt wurde noch kein Anspruch erhoben
                                    </Typography>
                                    <Button sx={{ display: 'flex', bgcolor: "primary.main", color:'common.white'}} variant="text" onClick={request_handler}>
                                        Klicken Sie hier, um dies jetzt zu tun
                                    </Button>
                                </Box>
                        }
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <a href={data.CAD} target="_blank">CAD</a>
                        <a>,&nbsp;</a>
                        <a href={data.Datasheets} target="_blank">Datenblätter</a>
                        <a>,&nbsp;</a>
                        <a href={data.instructions} target="_blank">Anleitungen</a>
                        <a>,&nbsp;</a>
                        <a href={data.otherDocs} target="_blank">andere Dokumente</a>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}
