import React from "react";
import {withAuthenticator} from '@aws-amplify/ui-react';
import MenuItem from '@mui/material/MenuItem';

//@ts-ignore
function Login({signOut,User}){

    return(
        <MenuItem onClick={signOut}>Log Out</MenuItem>
    );
}

export default withAuthenticator(Login)