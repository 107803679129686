import React,{useEffect, useState} from 'react';
import './App.css';
import Amplify, {Auth} from 'aws-amplify';
import awsExports from './aws-exports';
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    NavLink,
    useSearchParams,
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './components/login'
import SearchAppBar from "./components/AppBar";
import axios from "axios";
import ProductCard from "./components/ProductCard";


Amplify.configure(awsExports);





//@ts-ignore
function App() {
    const [user,setUser] = useState({attributes:{name:''}})
    const [login,setLogin] = useState(false)
    const [signOut,SetSignOut] = useState('')
    const [serialnumber, setSerialnumber] = useState('');
    const [items,setItems] = useState({})
    const [search,setSearch] = useState('')
    let apiClient = axios.create({
        baseURL: "https://api.schunk.kai-krieg.de",
        withCredentials: false,
        headers: {
            Accept: "application/json",
            Authorization: '',
            "Content-Type": "application/json",
        },
    });
    useEffect(() => {
        if(serialnumber.length>0){
            fetchData()
        }
    },[serialnumber,user.attributes.name])



    function Query(props:{queryID: string}){
        const queryID=props['queryID']
        const [searchParams, setSearchParams] = useSearchParams();
        let id = searchParams.get(queryID)
        if(id !== null){
            // console.log(id)
            setSerialnumber(id)
        }
        return(<div/>)
        // return(<div>{id}</div>)
    }

    const fetchData = async () =>{
        try{
            Auth.currentAuthenticatedUser().then((user)=>{
                setUser(user)
            }).catch((er)=>{
                console.log(er)
                setUser({attributes:{name:''}})
            })

            let token =''
            if(user.attributes.name!==''){
                //@ts-ignore
                token = user.signInUserSession.accessToken.jwtToken
                // requestInfo.headers.Authorization = token
                // apiClient.defaults.headers.get['Authorization']=token
            }
            apiClient.get('/object-data/?serialnummer='+serialnumber,{headers:{Authorization: token}})
                .then((info:Object)=>{
                    let newItems=items
                    //@ts-ignore
                    newItems[serialnumber]=info.data
                    setItems(newItems)
                    // console.log(info)
                    // console.log(items)
                }).catch((er:Object)=>{
                console.log(er)
            })


            // console.log({data})
            // setItems()
        }catch (error){
            console.log('fetching failed',error)
        }
    }


    useEffect(() => {
        document.title = "SCHUNK-Digital"
        setTimeout(()=>{
            fetchData()
        },1000)
    }, []);
    // console.log(user)

    useEffect(()=>{},[items])

    const theme = createTheme({
        palette: {
            primary: {
                main: '#003e68',
            },
            background:{
                default: '#ffffff',
                paper:'#ffffff'
            },
            text: {
                primary: '#151515',
                secondary: '#ffffff',
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <SearchAppBar setSearch={setSearch}/>
                <Query queryID={'serialnummer'}/>
                {/*<h1>Hello {user.attributes.name}</h1>*/}
                <button onClick={fetchData}>Refresh</button>
                {Object.keys(items).map(function(key, index) {
                    //@ts-ignore
                    let data=items[key]
                    data.uuid=key
                    data.user=user
                    return(<ProductCard data={data}/>)
                })}
            </Router>
        </ThemeProvider>
    );
}
//@ts-ignore
export default App;
